// import logo from './logo.svg';
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Index from "./user/Index";
import { Fragment, useEffect, useState } from "react";
import Product from "./user/Product";
import Posts from "./user/Posts";
// import Pagenotfound from "./user/Pagenotfound";
import Contact from "./user/Contact";
import About from "./user/About";
import Login from "./user/Login";
import Register from "./user/Register";
// import PasswordResetOtp from "./user/PasswordResetOtp";
// import ForgetPassword from "./user/ForgetPassword";
import InfoUser from "./user/InfoUser";
import ProductDetail from "./user/ProductDetail";
import ViewCart from "./user/ViewCart";
import Checkout from "./user/Checkout";
import BlogDetail from "./user/BlogDetail";
import Dashboard from "./admin/Dashboard";
import ProtectRoute from "./route/ProtectRoute";
import ProductList from "./admin/ProductList";
import ProductAdd from "./admin/ProductAdd";
import ProductEdit from "./admin/ProductEdit";
import PostList from "./admin/PostList";
import ProductCate from "./admin/ProductCate";
import PostAdd from "./admin/PostAdd";
import PostEdit from "./admin/PostEdit";
import UserList from "./admin/UserList";
// import UserAdd from "./admin/UserAdd";
// import UserEdit from "./admin/UserEdit";
import ProductCata from "./user/ProductCata";
import { CartProvider } from "./context/CartContext";
import BlogCate from "./admin/BlogCate";
import ProductCateEdit from "./admin/ProductCateEdit";
import BlogCateEdit from "./admin/BlogCateEdit";
import ContactAdmin from "./admin/ContactAdmin";
import CouponList from "./admin/CouponList";
import CommentList from "./admin/CommentList";
// import { useSelector } from "react-redux";
function App() {
  // console.log(data);
  return (
    <div className="App">
      <CartProvider>
      <BrowserRouter>
        <Routes>
          
            <Route path="/" element={<Index />} />
            {/* product */}
            <Route path="/pd/" element={<Product />} />
            <Route path="/pdt/danhmuc/:id" element={<ProductCata />} />
            {/* product */}
            <Route path="/pdt/:id" element={<ProductDetail />} />
            <Route path="/p/" element={<Posts />} />
            <Route path="/p/:id" element={<BlogDetail />} />
            <Route path="/c/" element={<Contact />} />
            <Route path="/a/" element={<About />} />
            <Route path="/lg/" element={<Login />} />
            <Route path="/rg/" element={<Register />} />
            <Route path="/crt/" element={<ViewCart />} />
            <Route path="/pm/" element={<Checkout />} />
          
          {/* <Route element={<Pagenotfound />} /> */}
          <Route element={<ProtectRoute />}>
            {/* <Route path="/u/rs-p" element={<PasswordResetOtp />} /> */}
            {/* <Route path="/u/fg-p" element={<ForgetPassword />} /> */}
            <Route path="/u/" element={<InfoUser />} />
            <Route path="/ad/" element={<Dashboard />} />
            {/* Product */}
            <Route path="/ad/prod-list/" element={<ProductList />} />
            <Route path="/ad/prod-add/" element={<ProductAdd />} />
            <Route path="/ad/prod-edit/:id" element={<ProductEdit />} />
            <Route path="/ad/prod-cate/" element={<ProductCate />} />
            <Route path="/ad/prod-cate/:id" element={<ProductCateEdit />} />
            {/* Post */}
            <Route path="/ad/post-list/" element={<PostList />} />
            <Route path="/ad/post-add/" element={<PostAdd />} />
            <Route path="/ad/post-edit/:id" element={<PostEdit />} />
            <Route path="/ad/post-blog/" element={<BlogCate/>}/>
            <Route path="/ad/post-blog/:id" element={<BlogCateEdit/>}/>
            {/* User */}
            <Route path="/ad/user-list/" element={<UserList />} />
            {/* <Route path="/ad/user-add/" element={<UserAdd />} /> */}
            {/* <Route path="/ad/user-edit/" element={<UserEdit />} /> */}
            {/* contact */}
            <Route path="/ad/contacts/" element={<ContactAdmin/>}/>
            {/* coupon */}
            <Route path="/ad/coupons/" element={<CouponList/>}/>
            {/* comments */}
            <Route path="/ad/comments/" element={<CommentList/>}/>
          </Route>
          {/* </Route> */}
        </Routes>
      </BrowserRouter>
      </CartProvider>
    </div>
  );
}

export default App;
