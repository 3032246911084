import React, { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import HeaderAdmin from './HeaderAdmin';

const CouponList = () => {
    useEffect(()=>{
        const s1 = document.createElement("script");
        s1.src = "https://code.jquery.com/jquery-3.5.1.min.js";
        s1.async = false;
        document.body.appendChild(s1);
    
        const s0 = document.createElement("script");
        s0.src = "/js/admin.js";
        s0.async = false;
        document.body.appendChild(s0);
    
        const s2 = document.createElement("script");
        s2.src = "https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.7/umd/popper.min.js";
        s2.async = false;
        document.body.appendChild(s2); 
    
        const s3 = document.createElement("script");
        s3.src = "https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/js/bootstrap.min.js";
        s3.async = false;
        document.body.appendChild(s3);
    
        return () => {
          document.body.removeChild(s1);
          document.body.removeChild(s0);
          document.body.removeChild(s2);
          document.body.removeChild(s3);
        }
      },[])
      const token = localStorage.getItem("token");
      const [coupon, setCoupon] = useState([])
      const addCoupon = {}
      const handleAdd = () => {
        fetch(`${process.env.REACT_APP_API_URL}/add_coupon`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify(addCoupon)
        })
          .then(res => res.json())
          .then(data => {
            console.log(data, 'san pham vua them');
          })
        window.location.href = '/ad/coupons/'
      }
      useEffect(()=>{
        fetch(`${process.env.REACT_APP_API_URL}/get_all_coupon`)
        .then(res=>res.json())
        .then(data=>setCoupon(data))
      },[])


      const handleDelete = (e, id) =>{
        e.preventDefault()
        alert('Do you want to delete this coupon?')
        fetch(`${process.env.REACT_APP_API_URL}/delete_coupon/${id}`,{
            method:'DELETE'
        })
        alert('Coupon delete successfully!')
      }
  return (
    <Fragment>
      <HeaderAdmin />
      <div id="wp-content">
        <div id="content" className="container-fluid">
          <div className="row">
            <div className="col-4">
              <div className="card">
                <div className="card-header font-weight-bold">
                  Mã giảm giá
                </div>
                <div className="card-body">
                  <form>
                    <div className="form-group">
                      <label htmlFor="name">Tên mã</label>
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        id="name"
                        onChange={e=>addCoupon.code = e.target.value}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="name">Giá trị giỏ hàng</label>
                      <input
                        className="form-control"
                        type="number"
                        onChange={e=>addCoupon.cart_value = e.target.value}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="name">%</label>
                      <input
                        className="form-control"
                        type="text"
                        onChange={e=>addCoupon.type = e.target.value}
                        value="percent"
                        readOnly
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="name">Giảm %</label>
                      <input
                        className="form-control"
                        type="number"
                        onChange={e=>addCoupon.coupon_value = e.target.value}
                      />
                    </div>
                    
                    <button type="button" className="btn btn-primary" onClick={handleAdd}>
                      Thêm mới
                    </button>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-8">
              <div className="card">
                <div className="card-header font-weight-bold">Danh sách Mã giảm giá</div>
                <div className="card-body">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Mã giảm giá</th>
                        <th scope="col">Giảm</th>
                        <th scope="col">Ngayf</th>
                        <th scope="col">Nút</th>
                      </tr>
                    </thead>
                    <tbody>
                      {coupon.map((item)=>(
                        <tr>
                          <th scope="row">{item.id}</th>
                          <td>{item.code}</td>
                          <td>{item.coupon_value}</td>
                          <td>{new Date(item.created_at).toLocaleString("vi-VN", {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                        })}</td>
                          <td><a href='/#' onClick={(e)=>handleDelete(e, item.id)}>Xóa</a></td>
                          {/* <td><Link to={'/ad/prod-cate/'+item.id}>Sửa</Link></td> */}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default CouponList