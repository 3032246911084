import React, { Fragment, useContext, useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { CartContext } from "../context/CartContext";

const ProductDetail = () => {
  useEffect(() => {
    const s0 = document.createElement("script");
    s0.src = "/vendor/jquery/jquery-3.2.1.min.js";
    s0.async = false;
    document.body.appendChild(s0);

    const s1 = document.createElement("script");
    s1.src = "/vendor/animsition/js/animsition.min.js";
    s1.async = false;
    document.body.appendChild(s1);

    const s2 = document.createElement("script");
    s2.src = "/vendor/slick/slick.min.js";
    s2.async = false;
    document.body.appendChild(s2);

    const s3 = document.createElement("script");
    s3.src = "/js/slick-custom.js";
    s3.async = false;
    document.body.appendChild(s3);

    const s4 = document.createElement("script");
    s4.src = "/js/main.js";
    s4.async = false;
    document.body.appendChild(s4);

    const s5 = document.createElement("script");
    s5.src = "/js/hiep.js";
    s5.async = false;
    document.body.appendChild(s5);

    const s6 = document.createElement("script");
    s6.src = "https://cdn.jsdelivr.net/npm/swiper@11/swiper-bundle.min.js";
    s6.async = false;
    document.body.appendChild(s6);

    const s7 = document.createElement("script");
    s7.src = "/js/contact.js";
    s7.async = false;
    document.body.appendChild(s7);

    return () => {
      document.body.removeChild(s0);
      document.body.removeChild(s1);
      document.body.removeChild(s2);
      document.body.removeChild(s3);
      document.body.removeChild(s4);
      document.body.removeChild(s5);
      document.body.removeChild(s6);
      document.body.removeChild(s7);
    };
  }, []);

  const { id } = useParams();
  const [data, setData] = useState([]);
  const [pro, setPro] = useState([]);
  const [cate, setCate] = useState([])
  const [comment, setComment] = useState([])
  const [u, setU] = useState([])
  const navigate = useNavigate()
  const { addToCart } = useContext(CartContext)
  const user = JSON.parse(localStorage.getItem("user"));
  const token = localStorage.getItem("token")
  // console.log(user.token);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/get_product_detail/` + id)
      .then((res) => res.json())
      .then((sp) => {
        setData(sp);
      });
  }, [id]);
  // console.log(data);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/get_category_withoutparentId`)
      .then((res) => res.json())
      .then((sp) => {
        setCate(sp);
      });
  }, []);
  // console.log(data.category_id);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/getcomments`)
      .then((res) => res.json())
      .then((sp) => {
        setComment(sp)
      })
  }, [])
  console.log(comment);

  const productComments = comment.filter((item) => item.product_id === Number(id));
  const n = 2
  const latestComments = productComments
    .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
    .slice(0, n);
  // console.log(user.user);
  const dangBl = {
    user_id: user?.id || null,
    product_id: Number(id) // cần cập nhật giá trị này từ input
  };

  const postComment = () => {
    if (user === null || !token) {
      alert('Bạn chưa đăng nhập, không thể bình luận được');
      return;
    }

    if (dangBl.comment === '') {
      alert('Vui lòng nhập nội dung bình luận');
      return;
    }

    fetch(`${process.env.REACT_APP_API_URL}/comments`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(dangBl),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! status: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
        console.log(data, 'Bình luận vừa thêm');
        alert('Bình luận của bạn đã được gửi thành công!');
        // Thêm logic để cập nhật giao diện nếu cần
      })
      .catch((error) => {
        console.error('Có lỗi xảy ra khi gửi bình luận:', error);
        alert('Không thể gửi bình luận, vui lòng thử lại sau.');
      });
    // window.location.reload() 
  };



  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/usersIndex`)
      .then((res) => res.json())
      .then((sp) => {
        setU(sp)
      })
  }, [])

  const pro_category = data.category_id;

  useEffect(() => {
    if (typeof pro_category !== "undefined" && pro_category !== 0) {
      fetch(`${process.env.REACT_APP_API_URL}/products/category/${pro_category}`)
        .then((res) => res.json())
        .then((sp) => {
          setPro(sp);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, [pro_category]);

  const product_meta = data.product_meta;



  const [selectedSize, setSelectedSize] = useState(null);
  // const [selectedColor, setSelectedColor] = useState(null);
  const [purchaseQuantity, setPurchaseQuantity] = useState(1);
  const uniqueSizes = Array.from(new Set((product_meta || []).map(product => product.size))).slice(0, 3);
  // const uniqueColors = Array.from(
  //   new Map((product_meta || []).map(product => [product.color_id, product.color])).values()
  // ).slice(0, 3);

  // console.log(purchaseQuantity);




  const handleSizeChange = (event) => {
    const selectedSizeId = parseInt(event.target.value);
    setSelectedSize(selectedSizeId);
  };

  // const handleColorChange = (event) => {
  //   const selectedColorId = parseInt(event.target.value);
  //   setSelectedColor(selectedColorId);
  // };

  const handleQuantityChange = (e) => {
    const findId = product_meta.find((item) => item.id)
    // console.log(findId.id);
    const product = product_meta.find(q => q.id === findId.id);
    // console.log(product);
    const newQuantity = parseInt(e.target.value, 10);
    // Kiểm tra số lượng người dùng muốn mua có hợp lệ không
    if (newQuantity > product.quantity) {
      alert(`Không đủ hàng trong kho. Số lượng có sẵn: ${product.quantity}`);
      setPurchaseQuantity(product.quantity); // Giới hạn ở số lượng có sẵn
    } else if (newQuantity < 1 || isNaN(newQuantity)) {
      alert("Số lượng mua phải ít nhất là 1");
      setPurchaseQuantity(1); // Giới hạn số lượng tối thiểu là 1
    } else {
      setPurchaseQuantity(newQuantity); // Cập nhật số lượng muốn mua
    }
  };
  // console.log(selectedSize, selectedColor);

  const chonSize = (
    <option>Chọn size</option>
  )
  // const chonColor = (
  //   <option>Chọn color</option>
  // )

  const handleAdd = () => {
    addToCart({ product_id: data.id, size_id: selectedSize, quantity: purchaseQuantity });
    navigate('/')
  }

  return (
    <Fragment>
      <Header />
      <div>
        {/* breadcrumb */}
        <div className="container">
          <div className="bread-crumb flex-w p-l-25 p-r-15 p-t-30 p-lr-0-lg">
            <Link to={'/'} className="stext-109 cl8 hov-cl1 trans-04">
              Home
              <i
                className="fa fa-angle-right m-l-9 m-r-10"
                aria-hidden="true"
              />
            </Link>
            <Link to={'/pd/'} className="stext-109 cl8 hov-cl1 trans-04">
              {cate.find(ct => ct.id === data.category_id)?.name}
              <i
                className="fa fa-angle-right m-l-9 m-r-10"
                aria-hidden="true"
              />
            </Link>
            <span className="stext-109 cl4">{data.name}</span>
          </div>
        </div>
        {/* Product Detail */}
        <section className="sec-product-detail bg0 p-t-65 p-b-60">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-lg-7 p-b-30">
                <div className="p-l-25 p-r-30 p-lr-0-lg">
                  <div className="wrap-slick3 flex-sb flex-w">
                    {/* <div className="wrap-slick3-dots" /> */}
                    <div className="wrap-slick3 flex-sb flex-w">
                    {/* <div className="wrap-slick3-dots" /> */}
                    <div className="wrap-slick3-arrows flex-sb-m flex-w" />

                    <div class="slick3 gallery-lb">
                      <div class="item-slick3" data-thumb={data.image_path}>
                        <div class="wrap-pic-w pos-relative">
                          <img src={data.image_path} alt="IMG-PRODUCT" />

                          <Link to={'/pdt/'+ data.id} class="flex-c-m size-108 how-pos1 bor0 fs-16 cl10 bg0 hov-btn3 trans-04">
                            <i class="fa fa-expand"></i>
                          </Link>
                        </div>
                      </div>

                      
                    </div>
                  </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-5 p-b-30">
                <div className="p-r-50 p-t-5 p-lr-0-lg">
                  <h4 className="mtext-105 cl2 js-name-detail p-b-14" style={{fontSize: 26 + 'px'}}>
                    {data.name}
                  </h4>
                  <p>Thương hiệu: <strong>LOCAL STORE®</strong> | Tình trạng: {data.status === 'in_stock' ? (<strong>Còn hàng</strong>) : (<strong>Hết hàng</strong>)}</p>
                  <span className="mtext-106 cl2" style={{fontSize: 30 + 'px'}}>{new Intl.NumberFormat('vi-VN', {
                    style: 'currency',
                    currency: 'VND',
                  }).format(data.price)}</span>

                  {/*  */}
                  <div className="p-t-33">
                    <div className="flex-w flex-r-m p-b-10">
                      <div className="size-203 flex-c-m respon6">Size</div>
                      <div className="size-204 respon6-next">
                        <div>
                          <select name="time" onChange={handleSizeChange}>
                            {[chonSize, ...uniqueSizes.map((meta) => (
                              <option value={meta.id}>{meta.name}</option>
                            ))]}
                          </select>
                          {/* <div class="dropDownSelect2"></div> */}
                        </div>
                      </div>

                    </div>
                    {/* <div className="flex-w flex-r-m p-b-10">
                      <div className="size-203 flex-c-m respon6">Màu</div>
                      <div className="size-204 respon6-next">
                        <div className="rs1-select2 bor8 bg0">
                          <select className="js-select2" name="time" onChange={handleColorChange}>
                            {[chonColor, ...uniqueColors.map((meta) => (
                              <option value={meta.id}>{meta.name}</option>
                            ))]}
                          </select>
                          <div className="dropDownSelect2" />
                        </div>
                      </div>
                    </div> */}
                    <div className="flex-w flex-r-m p-b-10">
                      <div className="size-204 flex-w flex-m respon6-next">
                        <div className="wrap-num-product flex-w m-r-20 m-tb-10">
                          <div className="btn-num-product-down cl8 hov-btn3 trans-04 flex-c-m">
                            <i className="fs-16 zmdi zmdi-minus" />
                          </div>
                          <input
                            className="mtext-104 cl3 txt-center num-product"
                            type="number"
                            name="num-product"
                            // defaultValue={1}
                            value={purchaseQuantity}
                            onChange={handleQuantityChange}
                          />
                          <div className="btn-num-product-up cl8 hov-btn3 trans-04 flex-c-m">
                            <i className="fs-16 zmdi zmdi-plus" />
                          </div>
                        </div>
                        <button className="flex-c-m stext-101 cl0 size-101 bg1 bor1 hov-btn1 p-lr-15 trans-04 js-addcart-detail" type="button" onClick={handleAdd}>
                          Add to cart
                        </button>
                      </div>
                    </div>
                  </div>

                </div>
                <p className="stext-102 cl3 p-t-23">
                  {data.description}
                </p>
              </div>
            </div>
            <div class="bor10 m-t-50 p-t-43 p-b-40">
              <div class="tab01">
                <ul class="nav nav-tabs" role="tablist">

                  <li class="nav-item p-b-10">
                    <a class="nav-link active" data-toggle="tab" role="tab">Khách hàng đánh giá </a>
                  </li>
                </ul>
                <div class="tab-content p-t-43">
                  {/* <div class="tab-pane fade show active" id="description" role="tabpanel">
                    <div class="how-pos2 p-lr-15-md">
                      <p class="stext-102 cl6">
                        <img src="../images/spchitiet/bangsize.jpg" width="100%" alt="" />
                      </p>
                    </div>
                  </div> */}
                  {/* <div class="tab-pane fade" id="information" role="tabpanel">
                    <div class="row">
                      <div class="col-sm-10 col-md-8 col-lg-6 m-lr-auto">
                        <ul class="p-lr-28 p-lr-15-sm">
                          <li class="flex-w flex-t p-b-7">
                            <span class="stext-102 cl3 size-205">
                              - FORM:
                            </span>
                            <span class="stext-102 cl6 size-206">
                              BOXY
                            </span>
                          </li>
                          <li class="flex-w flex-t p-b-7">
                            <span class="stext-102 cl3 size-205">
                              - FABRIC:
                            </span>
                            <span class="stext-102 cl6 size-206">
                              NỈ BÔNG
                            </span>
                          </li>
                          <li class="flex-w flex-t p-b-7">
                            <span class="stext-102 cl3 size-205">
                              - ĐỊNH LƯỢNG:
                            </span>
                            <span class="stext-102 cl6 size-206">
                              380GSM
                            </span>
                          </li>
                          <li class="flex-w flex-t p-b-7">
                            <span class="stext-102 cl3 size-205">
                              - DETAILS:
                            </span>
                            <span class="stext-102 cl6 size-206">
                              IN PHỒNG , MẶT SAU IN LỤA LOGO " WHENEVER ATELIER"
                            </span>
                          </li>
                          <hr />
                          <li class="flex-w flex-t p-b-7">
                            <span class="stext-102 cl3 size-205">
                              ĐÃ XỬ LÝ VẢI:
                            </span>

                            <span class="stext-102 cl6 size-206">
                              KHUYẾN KHÍCH KHÁCH MUA VỀ DÙNG LUÔN
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div> */}


                  <div class="tab-pane fade show active" id="reviews" role="tabpanel">
                    <div class="row">
                      <div class="col-sm-10 col-md-8 col-lg-6 m-lr-auto">
                        <div class="p-b-30 m-lr-15-sm">
                          {latestComments.length > 0 ? (
                            latestComments.slice(0, 2).map((item) => (
                              <div class="flex-w flex-t p-b-68">
                                <div class="wrap-pic-s size-109 bor0 of-hidden m-r-18 m-t-6">
                                  <img src="../images/icons/avatar-01.jpg" alt="AVATAR" />
                                </div>

                                <div class="size-207">
                                  <div class="flex-w flex-sb-m p-b-17">
                                    <span class="mtext-107 cl2 p-r-20">
                                      {u.find((user) => user.id === item.user_id)?.name || "Unknown User"}
                                    </span>

                                    {/* <span class="fs-18 cl11">
                                  <i class="zmdi zmdi-star"></i>
                                  <i class="zmdi zmdi-star"></i>
                                  <i class="zmdi zmdi-star"></i>
                                  <i class="zmdi zmdi-star"></i>
                                  <i class="zmdi zmdi-star-half"></i>
                                </span> */}
                                  </div>

                                  <p class="stext-102 cl6">
                                    Quá đẹp
                                  </p>
                                </div>
                              </div>
                            ))
                          ) : (

                            <p style={{ fontSize: 14 + 'px', paddingBottom: 30 + 'px', color: '#990000' }}>Sản phẩm chưa có bình luận</p>

                          )}




                          {/* <h5 class="mtext-108 cl2 p-b-7">
                              Thêm một bài đánh giá
                            </h5>

                            <p class="stext-102 cl6">
                              Địa chỉ email của bạn sẽ không được công bố. Các trường bắt buộc được đánh dấu **
                            </p> */}

                          {/* <div class="flex-w flex-m p-t-50 p-b-23">
                              <span class="stext-102 cl3 m-r-16">
                                Đánh giá
                              </span>

                              <span class="wrap-rating fs-18 cl11 pointer">
                                <i class="item-rating pointer zmdi zmdi-star-outline"></i>
                                <i class="item-rating pointer zmdi zmdi-star-outline"></i>
                                <i class="item-rating pointer zmdi zmdi-star-outline"></i>
                                <i class="item-rating pointer zmdi zmdi-star-outline"></i>
                                <i class="item-rating pointer zmdi zmdi-star-outline"></i>
                                <input class="dis-none" type="number" name="rating" />
                              </span>
                            </div> */}

                          <div class="row p-b-25">
                            <div class="col-12 p-b-5">
                              <label class="stext-102 cl3" for="review">Bình luận của bạn</label>
                              <textarea class="size-110 bor8 stext-102 cl2 p-lr-20 p-tb-10" id="review"
                                name="review" onChange={(e) => (dangBl.comment = e.target.value)}></textarea>
                            </div>


                          </div>

                          <button class="flex-c-m stext-101 cl0 size-112 bg7 bor11 hov-btn3 p-lr-15 trans-04 m-b-10" onClick={postComment}>
                            Gửi
                          </button>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div className="bg6 flex-c-m flex-w size-302 m-t-73 p-tb-15">
            {/* <span class="stext-107 cl6 p-lr-25">
				SKU: JAK-01
			</span>

			<span class="stext-107 cl6 p-lr-25">
				Categories: Jacket, Men
			</span> */}
          </div>
        </section>
        {/* <div className="container">
          <div class="comment-section">
            {productComments.length > 0 ? (
              productComments.map((item) => (
                <div className="comment" key={item.id}>
                  <div className="user">
                    {u.find((user) => user.id === item.user_id)?.name || "Unknown User"}
                  </div>
                  <div className="timestamp">
                    {new Date(item.created_at).toLocaleString("vi-VN", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </div>
                  <div className="message">{item.comment}</div>
                </div>
              ))
            ) : (
              <div className="comment">
                <p>Sản phẩm chưa có bình luận</p>
              </div>
            )}
            <textarea
              placeholder="Nhập bình luận của bạn"
              onChange={(e) => (dangBl.comment = e.target.value)}
              style={{ width: '100%', height: '100px' }}
            ></textarea>
            <button onClick={postComment}>Gửi bình luận</button>
          </div>
        </div> */}
        {/* Related Products */}
        <section className="sec-relate-product bg0 p-t-45 p-b-105">
          <div className="container">
            <div className="p-b-45">
              <h3 className="ltext-106 cl5 txt-center">SẢN PHẨM LIÊN QUAN</h3>
            </div>
            {/* Slide2 */}
            <div className="wrap-slick2">
              <div className="slick2">
                {pro.slice(0, 4).map((product, i) => (
                  <div className="item-slick2 p-l-15 p-r-15 p-t-15 p-b-15">
                    {/* Block2 */}
                    <div className="block2">
                      <div className="block2-pic hov-img0">
                        <img src={product.image_path} alt="IMG-PRODUCT" />
                      </div>
                      <div className="block2-txt flex-w flex-t p-t-14">
                        <div className="block2-txt-child1 flex-col-l ">
                          <a
                            href="product-detail.html"
                            className="stext-104 cl4 hov-cl1 trans-04 js-name-b2 p-b-6"
                          >
                            {product.name}
                          </a>
                          <span className="stext-105 cl3">{product.price}</span>
                        </div>
                        <div className="block2-txt-child2 flex-r p-t-3">
                          <a
                            href="/#"
                            className="btn-addwish-b2 dis-block pos-relative js-addwish-b2"
                          >
                            <img
                              className="icon-heart1 dis-block trans-04"
                              src="../images/icons/icon-heart-01.png"
                              alt="ICON"
                            />
                            <img
                              className="icon-heart2 dis-block trans-04 ab-t-l"
                              src="../images/icons/icon-heart-02.png"
                              alt="ICON"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </Fragment>
  );
};

export default ProductDetail;
