import React, { Fragment, useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Link } from "react-router-dom";
// import SearchBar from './SearchBar';

const Product = () => {
  useEffect(() => {
    const s0 = document.createElement("script");
    s0.src = "/vendor/jquery/jquery-3.2.1.min.js";
    s0.async = false;
    document.body.appendChild(s0);

    const s1 = document.createElement("script");
    s1.src = "/vendor/animsition/js/animsition.min.js";
    s1.async = false;
    document.body.appendChild(s1);

    const s2 = document.createElement("script");
    s2.src = "/vendor/slick/slick.min.js";
    s2.async = false;
    document.body.appendChild(s2);

    const s3 = document.createElement("script");
    s3.src = "/js/slick-custom.js";
    s3.async = false;
    document.body.appendChild(s3);

    const s4 = document.createElement("script");
    s4.src = "/js/main.js";
    s4.async = false;
    document.body.appendChild(s4);

    const s5 = document.createElement("script");
    s5.src = "/js/hiep.js";
    s5.async = false;
    document.body.appendChild(s5);

    const s6 = document.createElement("script");
    s6.src = "https://cdn.jsdelivr.net/npm/swiper@11/swiper-bundle.min.js";
    s6.async = false;
    document.body.appendChild(s6);

    return () => {
      document.body.removeChild(s0);
      document.body.removeChild(s1);
      document.body.removeChild(s2);
      document.body.removeChild(s3);
      document.body.removeChild(s4);
      document.body.removeChild(s5);
      document.body.removeChild(s6);
    };
  }, []);

  const tatca = (
    <button className="stext-106 cl6 hov1 bor3 trans-04 m-r-32 m-tb-5">
      <Link to={'/pd/'}>Tất cả sản phẩm</Link>
    </button>
  );

  const [pro, setPro] = useState([]);

  // console.log(pro);
  const [searhPro, setsearhPro] = useState([]);
  const [query, setQuery] = useState("");
  const handleInputChange = (e) => {
    const searchValue = e.target.value;
    setQuery(searchValue);

    if (searchValue.trim() === "") {
      setsearhPro([]); // Trả về danh sách trống khi không có từ khóa
      return;
    }

    // Gọi API tìm kiếm khi có từ khóa
    fetch(`${process.env.REACT_APP_API_URL}/search?query=${encodeURIComponent(searchValue)}`)
      .then((res) => res.json())
      .then((data) => setsearhPro(data))
      .catch((err) => console.error("Error fetching search results:", err));
  };
  console.log(searhPro);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/get_all_product`)
      .then((res) => res.json())
      .then((prod) => {
        setPro(prod);
      });
  }, []);

  const [cate, setCate] = useState([]);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/get_category_withoutparentId`)
      .then((res) => res.json())
      .then((blog) => {
        setCate(blog);
      });
  }, []);

  return (
    <Fragment>
      <Header />

      {/* Menu tất cả sản phẩm */}
      <div className="bg0 m-t-23 p-b-140">
        <div className="container">
          <div className="flex-w flex-sb-m p-b-52">
            <div className="flex-w flex-l-m filter-tope-group m-tb-10">
              {[tatca, cate.map((ct, i) => (
                <button
                  className="stext-106 cl6 hov1 bor3 trans-04 m-r-32 m-tb-5 how-active1"
                  data-filter=".balo"
                >
                  <Link to={"/pdt/danhmuc/" + ct.id}>{ct.name}</Link>
                </button>
              ))]}
            </div>

            <div className="flex-w flex-c-m m-tb-10">
             
              <div className="flex-c-m stext-106 cl6 size-105 bor4 pointer hov-btn3 trans-04 m-tb-4 js-show-search">
                <i className="icon-search cl2 m-r-6 fs-15 trans-04 zmdi zmdi-search" />
                <i className="icon-close-search cl2 m-r-6 fs-15 trans-04 zmdi zmdi-close dis-none" />
                Search
              </div>

            </div>

            {/* Search product */}
            <div className="dis-none panel-search w-full p-t-10 p-b-15">
              <div className="bor8 dis-flex p-l-15">
                <input
                  type="text"
                  placeholder="Tìm kiếm sản phẩm..."
                  value={query}
                  onChange={handleInputChange} // Gọi hàm khi nhập liệu
                  className="search-input"
                />

                <button className="size-113 flex-c-m fs-16 cl2 hov-cl1 trans-04">
                  {/* <i className="zmdi zmdi-search" /> */}
                </button>
              </div>
            </div>
            {/* Filter */}
            
          </div>
          <div className="row isotope-grid">
            {query.trim() === "" ? (
              pro.map((data, i) => (
                <div className="col-sm-6 col-md-4 col-lg-3 p-b-35 isotope-item women">
                  {/* Block2 */}
                  <div className="block2">
                    <div className="block2-pic hov-img0">
                      <Link to={"/pdt/" + data.id}>
                        <img src={data.image_path} alt="IMG-PRODUCT" />
                      </Link>
                    </div>
                    <div className="block2-txt flex-w flex-t p-t-14">
                      <div className="block2-txt-child1 flex-col-l ">
                        <Link
                          to={"/pdt/" + data.id}
                          className="stext-104 cl4 hov-cl1 trans-04 js-name-b2 p-b-6"
                        >
                          {data.name}
                        </Link>
                        <span className="stext-105 cl3">
                          {data.price.toLocaleString("vi-VN", {
                            style: "currency",
                            currency: "VND",
                          })}
                        </span>
                      </div>
                      <div className="block2-txt-child2 flex-r p-t-3">
                        <Link
                          to={"/pd/" + data.id}
                          className="btn-addwish-b2 dis-block pos-relative js-addwish-b2"
                        >
                          {/* <img
                            className="icon-heart1 dis-block trans-04"
                            src="../images/icons/icon-heart-01.png"
                            alt="ICON"
                          /> */}
                          <img
                            className="icon-heart2 dis-block trans-04 ab-t-l"
                            src="../images/icons/icon-heart-02.png"
                            alt="ICON"
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : searhPro.length > 0 ? (
              searhPro.map((data, i) => (
                <div className="col-sm-6 col-md-4 col-lg-3 p-b-35 isotope-item women">
                  {/* Block2 */}
                  <div className="block2">
                    <div className="block2-pic hov-img0">
                      <Link to={"/pdt/" + data.id}>
                        <img src={data.image_path} alt="IMG-PRODUCT" />
                      </Link>
                    </div>
                    <div className="block2-txt flex-w flex-t p-t-14">
                      <div className="block2-txt-child1 flex-col-l ">
                        <Link
                          to={"/pdt/" + data.id}
                          className="stext-104 cl4 hov-cl1 trans-04 js-name-b2 p-b-6"
                        >
                          {data.name}
                        </Link>
                        <span className="stext-105 cl3">
                          {data.price.toLocaleString("vi-VN", {
                            style: "currency",
                            currency: "VND",
                          })}
                        </span>
                      </div>
                      <div className="block2-txt-child2 flex-r p-t-3">
                        <Link
                          to={"/pd/" + data.id}
                          className="btn-addwish-b2 dis-block pos-relative js-addwish-b2"
                        >
                          {/* <img
                            className="icon-heart1 dis-block trans-04"
                            src="../images/icons/icon-heart-01.png"
                            alt="ICON"
                          /> */}
                          <img
                            className="icon-heart2 dis-block trans-04 ab-t-l"
                            src="../images/icons/icon-heart-02.png"
                            alt="ICON"
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              // Thông báo khi không tìm thấy kết quả
              <p>No products found</p>
            )}
          </div>
          {/* Load more */}
          <div className="flex-c-m flex-w w-full p-t-45">
            <a
              href="#"
              className="flex-c-m stext-101 cl5 size-103 bg2 bor1 hov-btn1 p-lr-15 trans-04"
            >
              Load More
            </a>
          </div>
        </div>
      </div>

      <Footer />
    </Fragment >
  );
};

export default Product;
